@import 'assets/styles/variable';
@import 'assets/styles//common/function.scss';

.loader {
	width: 60px;
}

.loader:before {
	content: '';
	display: block;
	padding-top: 100%;
}

.circular {
	animation: rotate 2s linear infinite;
	-webkit-animation: rotate 2s linear infinite;
	height: auto;
	transform-origin: center center;
	-webkit-transform-origin: center center;
	width: 60%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	stroke: $white;
}

.path {
	stroke-dasharray: 1, 200;
	stroke-dashoffset: 0;
	animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
	-webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
	strokelinecap: round;
}

@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}

	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35px;
	}

	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124px;
	}
}

@-webkit-keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

@-webkit-keyframes dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}

	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35px;
	}

	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124px;
	}
}

.form-loader {
	position: relative;

	.form-overlay-loader {
		z-index: 111;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		background-color: rgba($color: $white, $alpha: 0.75);
	}
}

.loader-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;

	.loading-icon {
		font-size: 24px;
	}
}

.st-loader-wrapper {
	position: fixed;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	backdrop-filter: blur(5px);
	background-color: rgba(255, 255, 255, 0.1);
	top: 0;
	left: 0;
	z-index: 9;
	display: flex;
	align-items: center;
	justify-content: center;
}

.new-loader {
	transform: rotateZ(45deg);
	perspective: 1000px;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	color: #17161a;

	&:before,
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: inherit;
		height: inherit;
		border-radius: 50%;
		transform: rotateX(70deg);
		animation: 1s spin linear infinite;
	}

	&:after {
		color: #25add0;
		transform: rotateY(70deg);
		animation-delay: 0.4s;
	}
}

@keyframes rotate {
	0% {
		transform: translate(-50%, -50%) rotateZ(0deg);
	}

	100% {
		transform: translate(-50%, -50%) rotateZ(360deg);
	}
}

@keyframes rotateccw {
	0% {
		transform: translate(-50%, -50%) rotate(0deg);
	}

	100% {
		transform: translate(-50%, -50%) rotate(-360deg);
	}
}

@keyframes spin {
	0%,
	100% {
		box-shadow: 0.3em 0px 0 0px currentcolor;
	}

	12% {
		box-shadow: 0.3em 0.3em 0 0 currentcolor;
	}

	25% {
		box-shadow: 0 0.3em 0 0px currentcolor;
	}

	37% {
		box-shadow: -0.3em 0.3em 0 0 currentcolor;
	}

	50% {
		box-shadow: -0.3em 0 0 0 currentcolor;
	}

	62% {
		box-shadow: -0.3em -0.3em 0 0 currentcolor;
	}

	75% {
		box-shadow: 0px -0.3em 0 0 currentcolor;
	}

	87% {
		box-shadow: 0.3em -0.3em 0 0 currentcolor;
	}
}
