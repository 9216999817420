@font-face {
	font-family: 'Roboto Slab';
	font-display: swap;
	font-weight: 400;
	src: url('../fonts/RobotoSlab-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto Slab';
	font-display: swap;
	font-weight: 700;
	src: url('../fonts/RobotoSlab-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto Slab';
	font-display: swap;
	font-weight: 800;
	src: url('../fonts/RobotoSlab-ExtraBold.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto Slab';
	font-display: swap;
	font-weight: 900;
	src: url('../fonts/RobotoSlab-Black.ttf') format('truetype');
}

@font-face {
	font-family: 'Lato';
	font-display: swap;
	font-weight: 800;
	src: url('../fonts/Lato-Bold.ttf') format('truetype');
}
