//Font family
$font-roboto: 'Roboto Slab';
$font-lato: 'Lato';

//Font Weight Variables
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semi-bold: 600;
$font-bold: 700;
$font-extra-bold: 800;
$font-heavy: 900;
$font-extra-heavy: 1000;

//Font size variables
$font-size-xxs: 0.75rem; // 12px
$font-size-xs: 0.8125rem; // 13px
$font-size-sm: 0.875rem; // 14px
$font-size-default: 0.9375rem; // 15px
$font-size-browser-default: 1rem; // 1rem = 16px
$font-size-md: 1.0625rem; // 17px
$font-size-lg: 1.125rem; // 18px
$font-size-xl: 1.1875rem; // 19px
$font-size-xxl: 1.25rem; // 20px

//Width and height variables
$full-width: 100%;
$full-height: 100%;
$full-viewport-width: 100vw;
$full-viewport-height: 100vh;

//Color Palette variables
$primary: #0081c9;
$secondary: #808080;
$white: #ffffff;
$black: #000000;
$gray: #444a51;
$green: #73a839;
$purple: #8500d6;
$info: #2fa4e7;
$pink: #f10be5;
$orange: #f1260b;
$catskillWhite: #f0f3f6;

$border-color: #d0d0d0;

$primary-box-shadow: 0px 4px 10px 0px rgba(16, 44, 87, 0.2);

$grey: (
	50: #bdbdbd,
	100: #828282,
	150: #4e4e4e,
	200: #464748,
	300: #262728,
	400: #252526,
	500: #262626,
	600: #515151,
	700: #d0d0d0
);

$red: (
	100: #eb5757,
	200: #ce3b4d,
	300: #f23131,
	400: #e33737,
	500: #ff0000,
	600: #d13838,
	700: #d62d20
);

$success: (
	50: #ecfdf5,
	100: #d1fae5,
	200: #a7f3d0,
	300: #6ee7b7,
	400: #34d399,
	500: #1f9471,
	600: #059669,
	700: #047857,
	800: #065f46,
	900: #064e3b,
	1000: #008744
);

//Line Height variables
$line-height-sm: 1;
$line-height-default: 1.2;
$line-height-md: 1.5;
$line-height-lg: 2;

//Border radius width variables
$border-radius-xxs: 2px;
$border-radius-xs: 3px;
$border-radius-sm: 4px;
$border-radius-default: 6px;
$border-radius-md: 8px;
$border-radius-lg: 10px;
$border-radius-xl: 16px;
$border-radius-xxl: 20px;
$border-radius-circle: 50%;

//latter Spacing variables
$letter-spacing-base: 0.5px !default;
$letter-spacing-lg: 2px !default;
$letter-spacing-xl: 4px !default;
