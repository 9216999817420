@media screen and (max-width: 425px) {
	.obssa-title {
		font-size: 16px;
	}

	.search-tab-wrapper {
		.search-result {
			.friends-list-wrapper {
				.friend-details-wrapper {
					max-width: calc(100vw - 170px);
				}
			}
		}
	}

	.profile-details-wrapper {
		.tab-wrapper {
			@include margin(10px 20px);
			.tab {
				font-size: $font-size-sm;
			}
		}

		.details-wrapper {
			height: calc(100vh - 363px);
			@include padding(0 15px);
			.details {
				@include padding(15px 0px);
			}

			.detail-label {
				font-size: $font-size-xs;
			}

			.detail-value {
				font-size: $font-size-xs;
			}
		}

		.profile-pic-wrapper {
			width: 90px;
			height: 90px;
		}

		.profile-pic-full-wrapper {
			width: 100vw;
			height: 100vh;
		}

		.alumni-name {
			font-size: $font-size-browser-default;
			line-height: 16px;
		}

		.roll-no {
			@include padding(2px 5px);
			font-size: $font-size-browser-default;
			line-height: 16px;
		}

		.personal-profile {
			height: calc(100vh - 470px);
		}

		.alumni-profile-details-wrapper {
			width: calc(100vw - 145px);
		}
	}

	.logout-wrapper {
		.logout-button,
		.primary-button {
			width: calc(50vw - 50px);
		}
	}
}

@media screen and (max-height: 900px) {
	.profile-details-wrapper {
		.details-wrapper {
			height: calc(100vh - 374px);
		}

		.personal-profile {
			height: calc(100vh - 444px);
		}
	}
}

@media screen and (max-height: 850px) {
	.profile-details-wrapper {
		.personal-profile {
			height: calc(100vh - 461px);
		}
	}

	.dashboard-wrapper {
		.updates-wrapper,
		.home-memories-wrapper,
		.memory-wrapper {
			.title {
				@include margin(12px 0 7px);
				font-size: 19px;
			}
		}
		.updates-wrapper {
			.title {
				@include margin(0 0 7px);
			}
			.birthday-alumni-wrapper {
				.alumni-name {
					font-size: $font-size-sm;
					@include paddingBottom(6px);
				}
			}
			.other-update {
				margin-top: 10px;
			}
		}
		.home-memories-wrapper {
			.featured-memory-img {
				height: 170px;
			}
		}

		.gmap-wrapper {
			height: 190px;

			.gmap-content-wrapper {
				.gmap-title {
					font-size: 20px;
				}
			}
		}
	}
}

@media screen and (max-height: 680px) {
	.dashboard-wrapper {
		.updates-wrapper,
		.home-memories-wrapper,
		.memory-wrapper {
			.title {
				@include margin(9px 0 7px);
				font-size: 17px;
			}
		}

		.updates-wrapper {
			.birthday-alumni-wrapper {
				.alumni-name {
					font-size: $font-size-sm;
					@include paddingBottom(6px);
				}

				.contact-wrapper {
					width: 34px;
					height: 34px;
				}
			}

			.swiper-wrapper {
				.alumni-update-swiper {
					@include paddingBottom(15px);
				}
			}
		}

		.gmap-wrapper {
			@include marginBottom(12px);
			height: 170px;

			.gmap-content-wrapper {
				.user-profile {
					font-size: $font-size-sm;
					width: 34px;
					height: 34px;
				}

				.gmap-title {
					font-size: 18px;
					line-height: 30px;
				}

				.next-icon-wrapper {
					width: 35px;
					height: 35px;
				}
			}
		}
	}
}
