.search-tab-wrapper {
	.search-tab {
		@include padding(20px);

		.search-field-wrapper {
			position: relative;
			width: calc(100% - 60px);

			.search-field {
				width: 100%;
				height: 45px;
				border-radius: 5px;
				border: 1px;
				background: #ffffff;
				border: 1px solid #d1dee5;
				font-size: $font-size-browser-default;
				@include padding(13px 0 15px 10px);
			}

			.search-field::-webkit-input-placeholder {
				font-size: $font-size-xxs;
			}

			.search-field:-ms-input-placeholder {
				font-size: $font-size-xxs;
			}

			.search-field::-ms-input-placeholder {
				font-size: $font-size-xxs;
			}

			.search-field::placeholder {
				font-size: $font-size-xxs;
			}

			.close-icon-wrapper {
				position: absolute;
				right: 14px;
				top: 16px;
				width: 19px;
				height: 19px;
				border-radius: 50%;
				background: #d1dee5;
				cursor: pointer;
				@extend .justify-align--center;
				z-index: 3;

				.close-icon {
					width: 7px;
					height: 7px;
				}
			}
		}

		.search-icon-wrapper {
			width: 45px;
			height: 45px;
			border-radius: 5px;
			background: $primary;
			box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.1);
			@extend .justify-align--center;
			@include marginLeft(10px);

			.search-icon {
				path {
					stroke: $white;
				}
			}
		}

		.search-friend-text {
			@include paddingTop(20px);
			opacity: 0.5;
		}
	}

	.search-result {
		@include padding(0px 20px);
		height: calc(100vh - 270px);
		overflow-y: auto;

		.search-img-wrapper {
			min-height: calc(100vh - 307px);
			@extend .justify-align--center;
			flex-direction: column;
		}

		.friends-list-wrapper:not(:first-child) {
			@include marginTop(20px);
		}

		.friends-list-wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			min-height: 88px;
			height: fit-content;
			@include margin(0px auto);
			@include padding(10px);
			border-radius: 5px;
			background: $white;
			border: 1px solid #d1dee5;
			box-shadow: 0px 4px 10px 0px #0000001a;

			.profile-image {
				width: 50px;
				height: 50px;
				border-radius: 50%;
				border: 3px solid #d1dee5;
				object-fit: cover;
				object-position: top;
				-o-object-position: top;
			}

			.friend-details-wrapper {
				@include margin(0 10px);
				max-width: 280px;

				.friend-name {
					font-size: $font-size-default;
					font-weight: $font-bold;
					line-height: 20px;
				}

				.city,
				.occupation {
					font-size: 11px;
					line-height: 13px;
					opacity: 0.5;
				}

				.location-wrapper {
					@include margin(3px 0 0 -2px);
				}
			}

			.mobile-icon-wrapper {
				width: 34px;
				height: 34px;
				border-radius: 5px;
				@extend .justify-align--center;
				box-shadow: 0px 2px 3px 0px #0000001a;
				cursor: pointer;

				.wp-icon {
					width: 30px;
					height: 30px;
				}

				.mobile-icon {
					width: 23px;
					height: 30px;
				}
			}
		}
	}
}

.infinite-scroll-component {
	overflow: hidden !important;
}
