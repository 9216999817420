.form-profile-wrapper {
	.logo {
		width: 40px;
		height: 40px;
		border: 1px solid $white;
	}

	.profile-header {
		@include padding(20px);
		max-width: 450px;
		background-color: #f5f7f9;

		.profile-header-wrapper {
			max-width: 450px;
			padding: 0;

			.auth-header {
				display: flex;
				align-items: center;

				.title {
					text-align: left;
					font-size: $font-size-sm;
				}
			}
		}
	}

	.profile-wrapper {
		@include margin(20px 20px 100px);
		max-width: 410px;
		width: calc(100vw - 40px);
		position: relative;
		background-color: #f5f7f9;

		.details-wrapper {
			@include margin(20px 0);
		}

		.next-button {
			@include button(false, 0px 10px, 75px, 40px);
			position: absolute;
			top: 0;
			right: 0;
			font-size: $font-size-xs;
		}

		.sticky-btn {
			position: fixed;
			top: 20px;
			right: 20px;
		}

		.stepper-wrapper {
			background: $white;
			@include padding(30px 20px);
			width: 100%;
			overflow-x: scroll;

			.bottom-active-border {
				border-radius: 10px;
				background: $secondary;
				width: 20px;
				height: 2px;
				position: absolute;
				left: 28%;
			}

			&::-webkit-scrollbar {
				width: 0px !important;
				box-shadow: none !important;
			}

			&::-webkit-scrollbar-thumb,
			&::-webkit-scrollbar-track {
				background-color: transparent;
				box-shadow: none !important;
			}

			span {
				font-family: $font-roboto;
				color: #0c356a80;
			}

			.active {
				border: 1.5px solid $white;
				box-shadow: 0px 4px 6px 0px rgba(12, 53, 106, 0.2);
				align-items: end;
			}

			.StepButton-0-2-4 {
				position: relative;
			}

			.completed {
				border: 1.5px solid $secondary;
			}

			.prev-tab {
				border: 1.5px solid transparent;

				&:hover {
					background-color: rgba(1, 190, 9, 0.1);
					opacity: 0.8;
				}
			}

			.active,
			.completed,
			.StepButton-d2-0-2-12 {
				z-index: 2;
			}

			.Connector-0-2-15 {
				z-index: 1;
				position: relative;
			}

			.profession-icon {
				span {
					height: 42px !important;
				}
			}

			.StepperContainer-0-2-1 {
				padding: 0;
				max-width: 822px;
				@include margin(0 auto);

				.StepContainer-0-2-2 {
					padding-left: 20px;
					padding-right: 20px;

					.StepButtonContent-0-2-5 {
						border: none;
						box-shadow: none;
						height: 30px;
						border-radius: 0 0 4px 4px;
						overflow: hidden;
					}
				}
			}

			.ConnectorContainer-0-2-14 {
				left: calc(-50% + 23px);
				right: calc(50% + 23px);
			}

			.ConnectorContainer-d18-0-2-74 {
				left: calc(-50% + 32px);
			}
		}

		.stepper-main-wrapper {
			background: $white;
			border-radius: 5px;
			@include padding(10px);
			width: 100%;
			display: flex;
			justify-content: center;

			&::-webkit-scrollbar {
				width: 0px !important;
				box-shadow: none !important;
			}

			&::-webkit-scrollbar-thumb,
			&::-webkit-scrollbar-track {
				background-color: transparent;
				box-shadow: none !important;
			}

			.profile-scroller {
				overflow-x: auto;
				overflow-y: hidden;
				width: calc(100vw - 20px);

				&::-webkit-scrollbar {
					width: 0px !important;
					height: 0px !important;
					box-shadow: none !important;
				}

				&::-webkit-scrollbar-thumb,
				&::-webkit-scrollbar-track {
					background-color: transparent;
					box-shadow: none !important;
				}
			}

			.stepper-inner-wrapper {
				overflow-x: scroll;
				width: -webkit-fit-content;
				width: -moz-fit-content;
				width: fit-content;
				display: flex;
				justify-content: flex-start;

				&::-webkit-scrollbar {
					display: none;
					width: 0px !important;
					box-shadow: none !important;
				}

				&::-webkit-scrollbar-thumb,
				&::-webkit-scrollbar-track {
					background-color: transparent;
					box-shadow: none !important;
				}

				&::after {
					width: 0;
					height: 0;
				}
			}

			.horizontal-stepper-wrapper {
				min-width: 80px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				@include margin(0 15px);

				&:first-child,
				&:last-child {
					@include margin(0px);
				}

				&:first-child {
					@include marginRight(15px);
				}

				&:last-child {
					@include marginLeft(15px);
				}

				.horizontal-stepper-box {
					width: 46px;
					height: 46px;
					border-radius: 10px;
					background-color: #f0f3f6;
					@extend .justify-align--center;
					z-index: 2;
					transition: all 0.3s ease-in-out;
					cursor: pointer;
					border: 2px solid $white;
					position: relative;
					@include padding(0);

					.stepper-line {
						position: absolute;
						width: 90px;
						border: 1px dashed rgba(12, 53, 106, 0.5);
						left: 43px;

						&--active {
							border: 1px solid $black;
						}
					}
				}

				.horizontal-stepper-fill-box {
					background: linear-gradient(0deg, #0174be, #0174be), linear-gradient(0deg, $white, $white);
					box-shadow: 0px 4px 6px 0px #0c356a33;
				}

				.disabled-stepper {
					cursor: not-allowed;
				}

				.horizontal-stepper-line {
					position: absolute;
					border: 1.5px dashed $border-color;
					left: 23px;
					z-index: 1;
				}

				.stepper-title {
					font-size: $font-size-xs;
					@include marginTop(5px);
					font-family: $font-roboto;
					color: rgba(12, 53, 106, 0.5);
					transition: all 0.3s ease-in-out;

					&--active {
						color: #0c356a;
					}
				}

				.stepper-hr {
					width: 20px;
					height: 2px;
					@include marginTop(5px);
					background-color: $white;
					border-radius: 10px;
					transition: all 0.3s ease-in-out;

					&--active {
						background-color: $secondary;
					}
				}
			}
		}

		.image-input {
			border-radius: 5px;
			background-color: $white;
			border: 1px solid $border-color;
			@include padding(7px);
		}

		.profile-container {
			border-radius: 5px;
			border: 1px solid #f0f2f4;
			background: $white;
			@include padding(15px 15px 0);
			@include margin(15px 0);
			display: flex;
			flex-wrap: wrap;

			.details-input-wrapper {
				width: 100%;

				.form-input {
					height: 40px;
					font-size: $font-size-sm;
				}

				textarea {
					height: 80px !important;
					@include paddingTop(10px);
					resize: none;
				}

				.react-datepicker__input-container {
					.calendar-icon {
						top: 12px;
					}
				}
			}

			.education-field-wrapper {
				flex-wrap: wrap;
			}

			.capture-photo {
				.profile-image {
					border-radius: 5px;
					background: $catskillWhite;
					height: 40px;
					width: 40px;
				}

				.file-upload {
					@include padding(15px);
				}
			}

			.family-details-wrapper {
				border-top: 1px solid #d1dee5;
				@include paddingTop(30px);
			}
		}

		.tab-option-container {
			gap: 20px;
			@include paddingTop(0px);

			.small-tab {
				width: 74px;
			}

			.big-tab {
				width: 100px;
			}

			.tab-option {
				@extend .justify-align--center;
				height: 40px;
				padding: 10px;
				border-radius: 5px;
				background: $catskillWhite;

				.tab-title {
					font-size: $font-size-sm;
					font-weight: $font-regular;
					opacity: 0.5;
					color: $secondary;
				}

				&--selected {
					background: #ffc436;

					.tab-title {
						font-weight: $font-medium;
						color: $black;
						opacity: 1;
					}
				}
			}
		}

		.contact-input-container {
			.contact-input-wrapper {
				width: 100%;

				.number-input {
					width: calc(100% - 50px);
					@include marginTop(0);
					height: 40px;
				}
			}

			.contact-input {
				width: 40px;
				height: 40px;
				@include padding(10px);
				@extend .justify-align--center;
			}

			.plus-icon {
				border-radius: $border-radius-md;
				border: 1px dashed $border-color;
				background: rgba(240, 243, 246, 0.5);
			}

			.transparent-border {
				border: 1px solid transparent;
			}
		}

		.prev-button {
			width: 100px;
			font-size: $font-size-xs;
		}

		.submit-button-container {
			display: flex;
			justify-content: flex-end;
		}

		.step-submit-button {
			@include button(false, 0px 10px, 240px, 40px);
			width: 100%;
			font-size: $font-size-xs;

			&:disabled {
				opacity: 0.5;
			}
		}

		.institute-container {
			position: relative;
			border-top: 1px solid $border-color;
			@include padding(30px 0 0);

			&:first-child {
				border-top: 1px solid transparent;
				@include padding(0);
			}
		}

		.delete-info-wrapper {
			cursor: pointer;
			width: 50px;
			height: 50px;
			border-radius: 8px;
			display: flex;
			justify-content: center;
			align-items: center;
			@include margin(31px 0 0 20px);
		}

		.delete-icon-wrapper {
			width: 20px;
			height: 20px;
			border-radius: 50%;
			@extend .justify-align--center;
			background: linear-gradient(0deg, rgba(190, 1, 1, 0.5), rgba(190, 1, 1, 0.5)),
				linear-gradient(0deg, #ffffff, #ffffff);
		}

		.delete-contact-number {
			width: 60px;
			@include margin(0);
		}

		.add-education-button {
			width: 380px;
			border: 1px dashed $border-color;
			background: rgba(240, 243, 246, 0.5);
			box-shadow: none;
			color: rgba($black, 0.5);
			@extend .flex--row-reverse;
		}

		.member-container {
			@include padding(15px);

			.toggle-input {
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				width: 75px;
				height: 32px;
				border: 1px solid $border-color;
				background: $catskillWhite;
				border-radius: 50px;
				transition: 0.4s;
				cursor: pointer;
				@include margin(0 0 0 10px);

				&::after {
					position: absolute;
					content: '';
					width: 20px;
					height: 20px;
					top: 5px;
					left: 6px;
					background: $black;
					opacity: 0.5;
					border-radius: $border-radius-circle;
					box-shadow: 0 0 5px rgba($black, 0.5);
					border: 0.5px solid $black;
					transform: scale(1.1);
					transition: 0.4s;
				}

				&::before {
					content: 'No';
					position: absolute;
					right: 11px;
					top: 7px;
					font-size: $font-size-xxs;
					font-weight: $font-regular;
					color: rgba($black, 0.5);
				}

				&:checked {
					background: $primary;

					&::after {
						left: 60%;
						background: $white;
						opacity: 1;
						border: 1px solid $border-color;
					}

					&::before {
						content: 'Yes';
						left: 9px;
						top: 7px;
						font-weight: $font-bold;
						color: $white;
					}
				}
			}
		}

		// map style

		.google-map-wrapper {
			width: 100%;
			height: 110px;
			position: relative;
			border-radius: 8px;
			border: 1px solid $border-color;
			margin-top: 120px;

			.map-container {
				.desktop-note {
					position: absolute;
					top: -48px;
				}

				.map-input {
					position: absolute;
					top: -121px;
				}

				.mobile-note {
					position: absolute;
					top: -48px;
				}

				.dropdown-wrapper {
					width: 100%;
					position: absolute;
					margin-top: 15;
					box-shadow: 0px 4px 6px 0px rgba(12, 53, 106, 0.2);
					top: -70px;
					left: 0;
					z-index: 9999;
					border-radius: $border-radius-md;
					background: $catskillWhite;

					.dropdown-option {
						background: $white;
						padding: 15px;
						color: $black;
						opacity: 0.5;
						font-size: 16px;
						font-weight: 400;
						border-bottom: 1px solid $border-color;
					}
				}
			}
		}

		.xxGHyP-dialog-view,
		.uNGBb-dialog-view--content {
			display: none !important;
		}
	}

	.roll-number-wrapper {
		@include padding(10px);
		box-shadow: 0px 10px 20px 0px rgba(12, 53, 106, 0.2);
		border: 1px solid #f0f2f4;
		background: linear-gradient(0deg, #f0f2f4, #f0f2f4), linear-gradient(0deg, #ffffff, #ffffff);
		border-radius: 5px;
		min-width: fit-content;

		.title {
			font-size: $font-size-sm;
			opacity: 0.5;
		}

		.roll-no {
			@include paddingLeft(5px);
			font-size: $font-size-sm;
			font-weight: $font-semi-bold;
		}
	}
}

//successful-completion screen

.successful-completion-wrapper {
	background: linear-gradient(180deg, #f8f0e5 0%, #ffffff 0.01%, #ffc436 110.62%);
	width: 100vw;
	max-width: 450px;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	.content-wrapper {
		position: relative;
		@extend .justify-align--center;
		width: 100%;
		max-width: 450px;
		height: 100%;
		@include padding(20px);
		border: 1px;
		flex-direction: column;
		box-shadow: 0px 10px 20px 0px #0c356a33;
		border: 1px solid #f0f2f4;
		background: linear-gradient(0deg, #f0f2f4, #f0f2f4), linear-gradient(0deg, #ffffff, #ffffff);
		border-radius: 40px 40px 0 0;
		min-height: calc(100vh - 100px);
		justify-content: space-between;

		.completion-title,
		.completion--sub-title {
			font-family: $font-roboto;
			font-size: 20px;
			line-height: 24px;
			@include marginTop(7px);
		}

		.completion-title {
			font-weight: $font-bold;
		}

		.completion--sub-title {
			font-weight: $font-regular;
			font-size: 16px;
			text-align: center;
		}

		.completion-msg {
			@include padding(15px 0);
			font-size: $font-size-xs;
			line-height: 20px;
			opacity: 0.7;
			text-align: center;
		}

		.view-profile-btn {
			width: 100%;
			max-width: 410px;
			width: calc(100vw - 40px);
			font-size: $font-size-sm;
		}

		.confetti-wrapper {
			position: absolute;
			width: 100%;
			height: 100%;
			overflow: hidden;
		}

		.heart-icon {
			color: #be0101;
		}

		.sponsor-msg {
			@include marginTop(30px);
			font-size: $font-size-sm;

			.scaletech-logo {
				@include marginLeft(7px);
				width: 40px;
				height: 40px;
			}
		}

		.footer-bottom-wrapper {
			position: absolute;
			bottom: 20px;
		}

		.sponsor-msg--link {
			text-decoration: none;
			display: flex;
			align-items: center;
			color: $black;
			font-size: $font-size-sm;
			@include marginLeft(5px);
		}
	}

	.auth-header-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include padding(0 27px 0 20px);
		max-width: 510px;
		height: 90px;

		.auth-header {
			@extend .justify-align--center;
			@include margin(20px 0 30px);
		}

		.logo {
			width: 40px;
			height: 40px;
			border: 1px solid $white;
		}

		.title {
			font-size: $font-size-sm;
			font-weight: $font-extra-bold;
			font-family: $font-roboto;
		}
	}
}
