.dashboard-wrapper {
	.gmap-wrapper {
		@include margin(20px);
		position: relative;
		width: calc(100% - 40px);
		height: 220px;
		border-radius: 5px;
		overflow: hidden;
		background-image: url('../../images/map.png');

		&::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: rgba($primary, 0.7);
		}

		.map-pin-icon-wrapper {
			width: 35px;
			height: 35px;
			border-radius: 5px;
			box-shadow: 0px 6px 15px 0px #00000033;
			background: $white;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;

			.map-pin-icon {
				width: 25px;
				height: 25px;
			}
		}

		.gmap-content-wrapper {
			@include padding(20px);
			position: relative;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.gmap-title,
			.gmap-users {
				color: $white;
				font-weight: $font-bold;
			}

			.gmap-title {
				font-size: 25px;
				line-height: 30px;
				@include margin(0);
			}

			.gmap-users {
				font-size: $font-size-xxs;
				line-height: 16px;
				@include marginBottom(5px);
			}

			.user-profile {
				width: 40px;
				height: 40px;
				border-radius: 50%;
				border: 2px solid #4aa5d7;
				@include marginRight(-10px);
				object-fit: cover;
				-o-object-fit: cover;
				object-position: top;
				-o-object-position: top;

				&--other {
					min-width: -webkit-fit-content;
					min-width: -moz-fit-content;
					min-width: fit-content;
					color: $primary;
					font-weight: $font-bold;
					background-color: #e9eff3;
					@extend .justify-align--center;
					@include padding(2px);
					font-size: $font-size-xxs;
				}
			}
		}
	}

	.home-memories-wrapper {
		@include padding(0 20px);

		.swiper-wrapper {
			max-width: 100vw;
		}

		.title {
			font-size: 22px;
			font-weight: $font-bold;
			line-height: 29px;
			@include margin(20px 0 14px);
		}

		.featured-memory-img {
			width: 100%;
			max-width: 410px;
			height: 220px;
			object-fit: cover;
			object-position: top;
			-o-object-position: top;
			cursor: pointer;
			border-radius: 5px;
		}

		.swiper {
			@include paddingBottom(20px);

			.swiper-pagination {
				.swiper-pagination-bullet {
					width: 10px;
					height: 4px;
					background-color: $primary;
				}
			}

			.memory-details {
				position: absolute;
				bottom: 20px;
				left: 20px;
				width: calc(100% - 40px);
				@extend .flex;
				justify-content: space-between;

				.memory-date {
					width: -webkit-fit-content;
					width: -moz-fit-content;
					width: fit-content;
					@include padding(2px 6px);
					color: $primary;
					border-radius: 3px;
					background: $white;
					font-size: $font-size-xxs;
				}

				.memory-name {
					font-weight: $font-bold;
					color: $white;
				}
			}
		}
	}

	.memory-wrapper {
		@include padding(0 20px);
		display: flex;
		flex-wrap: wrap;

		.title {
			font-size: 22px;
			font-weight: $font-bold;
			line-height: 29px;
			@include margin(20px 0 14px);
		}

		.memory-box-wrapper {
			width: calc(50% - 10px);
			height: 220px;
			border-radius: 5px;
			position: relative;
			@include marginBottom(20px);
			cursor: pointer;

			.memory-img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 5px;
				object-position: top;
				-o-object-position: top;
			}

			.memory-date {
				width: -webkit-fit-content;
				width: -moz-fit-content;
				width: fit-content;
				@include padding(2px 6px);
				color: $primary;
				border-radius: 3px;
				background: $white;
				position: absolute;
				top: 10px;
				left: 10px;
				font-size: $font-size-xxs;
			}

			.memory-details {
				position: absolute;
				bottom: 10px;
				left: 10px;
				width: calc(100% - 20px);
				display: flex;
				align-items: center;
				justify-content: space-between;

				.memory-name {
					font-weight: $font-bold;
					color: $white;
					width: calc(100% - 20px);
					line-height: 21px;
				}

				.memory-icon {
					path {
						stroke: $white;
					}
				}
			}
		}
	}

	.see-all {
		cursor: pointer;
		font-size: $font-size-sm;
		color: $primary;

		svg {
			@include paddingTop(2px);

			path {
				stroke: $primary;
			}
		}
	}

	.all-memory-wrapper {
		position: fixed;
		width: 100%;
		height: 100vh;
		max-width: 450px;
		top: 0;
		background-color: $white;
		z-index: 3;
	}

	.memory-swiper-wrapper {
		z-index: 7;
	}

	.updates-wrapper {
		width: calc(100vw - 40px);
		max-width: 410px;
		@include margin(0 20px);

		.swiper-wrapper {
			.alumni-update-swiper {
				@include paddingBottom(20px);

				.swiper-pagination {
					bottom: 0px;

					.swiper-pagination-bullet {
						width: 10px;
						height: 4px;
						background-color: $primary;
					}
				}
			}
		}

		.title {
			font-size: 22px;
			font-weight: $font-bold;
			line-height: 29px;
			@include margin(20px 0 14px);
		}

		.birthday-alumni-wrapper {
			@include padding(10px);
			border: 1px solid #e9eff3;
			box-shadow: 0px 2px 10px 0px #0000001a;

			.birthday-alumni-details {
				width: calc(100% - 65px);
			}

			.contact-wrapper {
				width: 40px;
				height: 40px;
				border-radius: 6px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				box-shadow: 0px 2px 3px 0px #0000001a;

				.email-icon {
					width: 20px;
					height: 30px;

					path {
						fill: $primary;
						stroke: $white;
					}
				}

				.mobile-icon {
					width: 23px;
					height: 30px;
				}

				.wp-icon {
					width: 30px;
					height: 30px;
				}
			}

			.alumni-img {
				width: 45px;
				height: 45px;
				border-radius: 6px;
				@include marginRight(10px);
				object-fit: cover;
				-o-object-fit: cover;
				object-position: top;
				-o-object-position: top;
			}

			.alumni-name {
				font-size: $font-size-lg;
				line-height: 22px;
				@include paddingBottom(11px);
				@include marginRight(20px);
				max-width: calc(100vw - 240px);
				width: -webkit-fit-content;
				width: -moz-fit-content;
				width: fit-content;
			}

			.alumni-number {
				font-size: $font-size-sm;
				line-height: 15px;
			}
		}

		.update-img-wrapper {
			width: 80px;
			height: 80px;
			border-radius: 5px;
			background: #e9eff380;
			display: flex;
			align-items: center;
			justify-content: center;
			@include marginRight(10px);
			@include padding(20px);
		}

		.news-type {
			@include padding(5px 10px);
			font-size: $font-size-xxs;
			color: $black;
			border-radius: 30px;
			width: -webkit-fit-content;
			width: -moz-fit-content;
			width: fit-content;
			@include marginBottom(10px);
		}
	}

	.constitution-tab-wrapper {
		@include margin(20px 20px 0);
		cursor: pointer;
	}
}

.memory-photo-swiper-wrapper {
	position: fixed;
	width: 100vw;
	height: 100vh;
	max-width: 450px;
	z-index: 8;
	background: #2b2c2e;
	top: 0;

	.header-icon-wrapper {
		width: 40px;
		height: 40px;
		border-radius: 5px;
		background: $white;
		box-shadow: 0px 2px 3px 0px #0000001a;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		@include marginRight(20px);
	}

	.details-memory--title {
		font-size: $font-size-lg;
		font-weight: $font-bold;
		color: $white;
	}

	.swiper-wrapper {
		position: relative;
		max-width: 100vw;
		height: calc(100vh - 60px);

		.swiper-slide {
			@include margin(10px 0);
			height: calc(100vh - 121px);
		}

		.alumni-update-swiper {
			.swiper-pagination {
				color: $white;
				bottom: 20px;

				.swiper-pagination-total,
				.swiper-pagination-current {
					color: $white;
				}
			}

			.swiper-button-prev,
			.swiper-button-next {
				top: unset;
				bottom: 20px;
				color: $white;
				height: 16px;
				z-index: 11;

				&::after {
					font-size: $font-size-browser-default;
				}
			}
		}

		.alumni-alumni-wrapper {
			.alumni-memory-img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}
}
