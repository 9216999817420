*,
*::after,
*::before {
	box-sizing: inherit;
}

* {
	font-family: $font-roboto;
	box-sizing: border-box;
	letter-spacing: 0.5px;
	-webkit-tap-highlight-color: transparent;
}

body {
	@include margin(0 auto);
	@include padding(0);
	background-color: #fafcff;
	font-size: $font-size-browser-default;
	font-weight: $font-regular;
	color: $black;
	scroll-behavior: smooth;
	overflow-x: hidden;
	max-width: 450px;
	height: 100vh;
	box-shadow: 0px 3px 5px 0px #0000001a;
	font-family: $font-roboto;
}

::-webkit-scrollbar {
	width: 4px;
	border-radius: $border-radius-default;
	box-shadow: inset 0 0 4px -0.5px rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-track {
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	border-radius: $border-radius-default;
	background-color: rgba($primary, 0.5);
}

a {
	text-decoration: none;
}

a:hover,
a:active,
a:focus {
	outline: 0;
}

h1,
h2,
h3,
h5,
p {
	margin: 0;
}

ul {
	@include padding(0);
	list-style: none;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
	@include margin(0);
}

input[type='number'] {
	-moz-appearance: textfield;
}

input {
	-webkit-appearance: none;
	-webkit-user-select: text;
	box-sizing: border-box;
	outline: none;
	border: none;
}

button {
	cursor: pointer;
	outline: none;
	box-sizing: border-box;
}

button::-moz-focus-inner {
	border: 0;
}

button {
	border: 0;
	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
}

h1 {
	font-size: 3rem;

	&.size--small {
		font-size: 2.125rem;
	}
}

h2 {
	font-size: 2.4375rem;

	&.size--small {
		font-size: 2.0625rem;
	}
}

h3 {
	font-size: 2.0625rem;

	&.size--small {
		font-size: 1.75rem;
	}
}

h4 {
	font-size: 2.0625rem;

	&.size--small {
		font-size: 1.4375rem;
	}
}

h5 {
	font-size: 1.4375rem;

	&.size--small {
		font-size: 1.1875rem;
	}
}

h6 {
	font-size: 1.1875rem;

	&.size--small {
		font-size: 1rem;
	}
}

.main-content-wrapper {
	@include paddingTop(90px);
	height: 100%;
	max-height: calc(100vh - 112px);
	width: 100%;
	overflow-x: hidden;

	&::-webkit-scrollbar-thumb,
	&::-webkit-scrollbar-track {
		background-color: transparent;
		box-shadow: none !important;
	}

	&::-webkit-scrollbar {
		display: none;
		width: 0px !important;
		box-shadow: none !important;
	}
}

.sign-up-content-wrapper {
	@include paddingTop(0px);
	background-color: #f5f7f9;
}

.successful-content-wrapper {
	max-height: 100%;
}

.profile-details-content-wrapper {
	max-height: calc(100vh - 80px);
}

.react-select-container {
	.select__control {
		.select__value-container {
			&::-webkit-scrollbar {
				display: none;
			}
		}
	}
}

.checkbox-wrapper {
	display: block;
	position: relative;
	padding: 8px 8px 8px 35px;
	cursor: pointer;
	line-height: 25px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	min-height: 41px;

	input {
		position: absolute;
		opacity: 0;
		height: 0;
		cursor: pointer;
		width: 0;
		&:checked ~ .check-mark {
			border-color: $primary;
			background-color: $primary;
		}
		&:checked ~ .check-mark:after {
			display: block;
		}
	}

	.check-mark {
		position: absolute;
		top: 8px;
		left: 8px;
		height: 25px;
		width: 25px;
		border: 1px solid $border-color;
		border-radius: 2px;

		&::after {
			content: '';
			position: absolute;
			display: none;
			left: 8px;
			top: 3px;
			width: 7px;
			height: 13px;
			border: solid $white;
			border-width: 0 2px 2px 0;
			transform: rotate(45deg);
		}
	}

	&.disabled {
		opacity: 0.5;
		cursor: not-allowed;

		.check-mark {
			pointer-events: none;
			border-color: $border-color !important;
		}
	}
	&:hover {
		input ~ .check-mark {
			border-color: $primary;
		}
	}
}
.upload-label {
	background-color: #ddd;
	color: #666666 !important;
}
