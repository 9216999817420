.accordion-container {
	margin-top: 30px;
	position: relative;

	.accordion-content-wrapper {
		width: calc(100% - 56px);
		background-color: $white;
		@include padding(5px 10px);
		border-radius: 5px;
		margin-bottom: 20px;

		.house-tab {
			min-width: calc(33% - 5px);
			height: 24px;
			@include padding(7px 10px);
			border-radius: 21px;
			background: #ffecec;
			@extend .justify-align--center;
			@include margin(0 5px 5px 0);
		}

		.text--blue {
			color: #0174be;
		}

		.divider {
			border-top: 1px solid #f5f7f9;
		}

		.single-details {
			@include padding(10px 0 0);
		}

		.details--title {
			height: 36px;
			transition: height 1s ease-out;

			.icon-wrapper {
				width: 30px;
				height: 30px;
				border-radius: 5px;
				background: $catskillWhite;
				@extend .justify-align--center;

				.down-icon {
					transform: rotate(0deg);
				}

				.up-icon {
					transform: rotate(180deg);
				}
			}
		}

		.inner-content {
			height: auto;
			transition: height 1s ease-out;
		}

		.profile-pic-wrapper {
			width: 40px;
			height: 40px;
			border-radius: $border-radius-circle;
			border: 1px solid $border-color;
			margin-right: 10px;
		}

		.profile-pic-full-wrapper {
			position: fixed;
			width: 100vw;
			height: 100vh;
			overflow: hidden;
			backdrop-filter: blur(5px);
			background-color: rgba(0, 0, 0, 0.3);
			top: 0;
			left: 0;
			z-index: 9;
			border-radius: 0;
			border: none;
			@include margin(0);

			.profile-full-img {
				border-radius: 0;
			}

			.close--icon {
				position: absolute;
				right: 10px;
				top: 10px;
				padding: 3px;
				background-color: #dddddd;
				border-radius: 4px;
				cursor: pointer;
				pointer-events: none;

				g {
					opacity: 1;
				}
			}
		}
	}

	.open-card {
		box-shadow: $primary-box-shadow;
	}

	.vertical-stepper-wrapper {
		width: 46px;
		height: 46px;
		border-radius: 10px;
		@include marginRight(10px);
		@extend .justify-align--center;
		z-index: 2;
		border: 2px solid $white;
		background: $white;
		transition: all 0.3s ease-in-out;
	}

	.vertical-stepper-fill-wrapper {
		background: linear-gradient(0deg, #0174be, #0174be), linear-gradient(0deg, #ffffff, #ffffff);
		box-shadow: 0px 4px 6px 0px #0c356a33;
	}

	.vertical-stepper-line {
		position: absolute;
		border: 1.5px dashed $border-color;
		left: 23px;
		z-index: 1;
	}
}

.details-header {
	@include padding(15px);

	.edit-button {
		width: 90px;
		height: 40px;
		@include marginLeft(20px);
		font-size: $font-size-sm;
		background-color: $primary;
	}
}

.profile-details-header-wrapper {
	max-width: 450px;
}
