//login Form
.full-page {
	width: $full-width;
	height: $full-viewport-height;
}

.login-wrapper,
.splash-screen-wrapper {
	position: relative;
	height: 100vh;
	@extend %flex;
	@extend %justify-content--between;
	@extend %flex--column;
	@extend %align-items--center;
	@include margin(0 auto);
	max-width: 428px;
	z-index: 1;

	&::before {
		content: '';
		position: absolute;
		width: 804px;
		height: 804px;
		border-radius: 100%;
		background: linear-gradient(180deg, #ffffff 0%, rgba(242, 244, 248, 0.984375) 0.01%, #ffc93c 117.23%);
		opacity: 0.2;
	}
}

.login-wrapper {
	overflow-x: hidden;
	overflow-y: auto;

	&::before {
		top: -15vh;
		max-height: calc(100vh - 70px);
	}

	.login-inner-wrapper {
		width: 100%;
		@include padding(20px);
		z-index: 2;

		&::-webkit-scrollbar {
			width: 0px !important;
			box-shadow: none !important;
		}

		&::-webkit-scrollbar-thumb,
		&::-webkit-scrollbar-track {
			background-color: transparent;
			box-shadow: none !important;
		}
	}

	.otp-inner-wrapper {
		@include paddingTop(40px);
	}

	.login-title {
		width: 100%;
		text-align: left;
		font-size: 32px;
		font-weight: $font-bold;
	}

	.login--sub-title {
		color: $secondary;
	}

	.otp-screen-wrapper {
		width: 100%;

		.otp-form-wrapper {
			justify-content: space-between;
			margin: 0 auto;

			.otp-input-box {
				width: 40px !important;
				height: 50px;
				@include margin(10px 5px);
				@include padding(10px);
				border: 1px solid $border-color;
				text-align: center;
				border-radius: 5px;
				font-size: $font-size-xs;
				transition: all 0.3s ease-in-out;
				-webkit-appearance: none;

				&::-moz-selection {
					background: transparent;
					/* WebKit/Blink Browsers */
				}

				&::selection {
					background: transparent;
					/* WebKit/Blink Browsers */
				}

				&::-moz-selection {
					background: transparent;
					/* Gecko Browsers */
				}

				&::-webkit-input-placeholder {
					opacity: 0.5;
				}

				&::-moz-placeholder {
					opacity: 0.5;
				}

				&:-ms-input-placeholder {
					opacity: 0.5;
				}

				&::-ms-input-placeholder {
					opacity: 0.5;
				}

				&::placeholder {
					opacity: 0.5;
				}

				&:focus {
					box-shadow: 0px 4px 10px 0px #0000001a;
				}
			}
		}

		.resend-otp-timer {
			font-size: $font-size-xs;
			line-height: 20px;
			color: rgba(0, 0, 0, 0.5);
			text-align: center;
			color: $primary;
		}

		.resend-otp-timer {
			font-size: $font-size-sm;
			@include marginTop(10px);
		}

		.resend-otp-text {
			font-size: $font-size-browser-default;
			text-align: center;
			@include marginTop(10px);
			color: $primary;
			font-weight: $font-medium;
		}

		.resend-otp-title {
			text-align: center;
			color: $secondary;
		}

		.otp-timer-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

.splash-screen-wrapper {
	overflow: hidden;
}

.form {
	.form-item {
		.input-field {
			background-color: $white;
			min-height: 40px;
			@include padding(5px 10px);
			color: $gray;
			transition: all 0.2s ease-in-out;
		}

		input:-webkit-autofill {
			-webkit-text-fill-color: $gray;
		}

		input:-webkit-autofill:focus {
			-webkit-text-fill-color: $gray;
			background-color: $white;
		}

		.password-icon {
			top: 21px;
			right: 25px;
			width: 28px;
		}

		.error-message {
			left: 0;
			bottom: -17px;
		}
	}

	.login-btn {
		width: 100%;
		@include padding(0 15px);
		min-height: 40px;
		background-color: $primary;
		border-radius: 5px;
	}

	.field-wrapper {
		.height--71 {
			height: 71px;
		}

		.input-field {
			border-radius: 5px;
			border: 1px solid $border-color;
			background: $white;
			@include padding(17px 13px 18px 13px);
			max-height: 50px;
			font-size: $font-size-sm;
			z-index: 2;
		}

		.field-title {
			font-size: $font-size-lg;
		}

		.error {
			margin-top: 1px;
		}
	}
}

.auth-title {
	@include margin(10px 0 40px);
}

.auth-form {
	width: 100%;
	border-radius: $border-radius-default;
	@extend %box-size--border-box;

	.welcome-img {
		position: absolute;
		bottom: 62px;
		right: 0;
	}
}

// ResetPassword Form
.reset-password_form {
	&.form {
		.form-item {
			.password-icon {
				top: 50px;
			}
		}
	}

	.reset-password-btn {
		min-height: 55px;
	}
}

//Splash screen
.splash-screen-wrapper {
	&::before {
		top: 65vh;
		transform: rotateX(180deg);
	}

	.obssa-logo {
		border-radius: 35px;
		border: 9px solid #ffffff;
		box-shadow: 0px 6px 10px 0px #00000040;
		@include marginBottom(50px);
	}

	.obssa-title {
		text-align: center;
		@include padding(20px 20px 70px);
	}

	.obssa-title,
	.obssa-sub-title {
		font-size: 32px;
		font-weight: $font-extra-bold;
		font-family: $font-lato;
	}
}

//Back button
.back-button {
	max-width: 40px;
	@include padding(9px);
	@include marginBottom(40px);
}
