.profile-wrapper {
	position: fixed;
	width: 100vw;
	max-width: 450px;
	height: 100vh;
	top: 0;
	z-index: 3;
	background-color: $white;

	.alumni-profile-wrapper {
		display: flex;
		align-items: center;
		box-sizing: border-box;
		@include padding(25px 20px 25px);
		background: linear-gradient(
			180deg,
			rgba(255, 255, 255) -72.92%,
			rgba(242, 244, 248) -72.9%,
			rgba(255, 201, 60, 0.2) 100%
		);

		.back-icon-wrapper {
			width: 40px;
			height: 40px;
			border-radius: 5px;
			box-shadow: 0px 2px 3px 0px #0000001a;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			background: $white;
		}
	}
}

.profile-details-wrapper {
	background-color: $white;

	.profile-pic-wrapper {
		width: 120px;
		height: 120px;
		border-radius: 8px;
		border: 3px solid #d1dee5;
		@include marginRight(15px);

		.profile-image {
			width: 100%;
			height: 100%;
			-o-object-fit: cover;
			object-fit: cover;
			border-radius: 5px;
			object-position: top;
			-o-object-position: top;
		}
	}

	.alumni-name {
		font-size: $font-size-xl;
		font-weight: $font-bold;
		line-height: 24px;
	}

	.batch,
	.occupation {
		font-size: $font-size-xs;
		line-height: 15px;
		opacity: 0.7;
	}

	.roll-no {
		background: #ffc93c;
		@include padding(4px 8px);
		border-radius: 3px;
		color: $white;
		font-size: $font-size-default;
		font-weight: $font-semi-bold;
		line-height: 24px;
		@include marginRight(10px);
	}

	.life-member {
		font-size: $font-size-sm;
		font-weight: $font-semi-bold;
		line-height: 18px;
		color: #00c968;
	}

	.alumni-contact-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include margin(0 20px 10px);
		@include paddingBottom(15px);
		border-bottom: 1px solid #d1dee580;

		.contact-wrapper {
			width: calc(33.33% - 10px);
			height: 60px;
			border-radius: 5px;
			background: $white;
			box-shadow: 0px 3px 10px 0px #0000001a;
			@extend .justify-align--center;
			flex-direction: column;

			.mobile-icon,
			.email-icon {
				width: 23px;
				height: 30px;
			}

			.email-icon {
				width: 20px;
			}

			.wp-icon {
				width: 30px;
				height: 30px;
			}

			.contact {
				@include marginTop(3px);
				font-size: $font-size-sm;
				line-height: 18px;
				color: #666f7e;
			}
		}
	}

	.tab-wrapper {
		position: relative;
		@include margin(20px);
		@include padding(8px 0);
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 1px solid #d1dee580;
		border-radius: 5px;
		overflow: hidden;

		&::before {
			content: '';
			position: absolute;
			width: calc(100% / 3);
			top: 0;
			height: 100%;
			transition: 0.3s ease-in-out;
			background: $primary;
		}

		&--personal {
			&::before {
				left: calc(0 * (100% / 3));
			}
		}

		&--professional {
			&::before {
				left: calc(1 * (100% / 3));
			}
		}

		&--family {
			&::before {
				left: calc(2 * (100% / 3));
			}
		}

		.tab {
			transition: all 0.3s ease-in-out;
			color: $black;
			cursor: pointer;
			border-radius: 3px;
			text-align: center;
			font-weight: $font-semi-bold;
			text-transform: capitalize;
			z-index: 2;
			width: 33.33%;

			&--active {
				color: $white;
			}
		}
	}

	.details-wrapper {
		border-radius: 5px;
		@include margin(0 20px);
		@include padding(0 20px);
		border: 1px solid #d1dee580;
		position: relative;
		z-index: 4;
		background-color: $white;
		overflow-y: auto;
		height: calc(100vh - 430px);

		&::before {
			content: '';
			position: absolute;
			top: -20px;
			width: 0px;
			height: 0px;
			border: 10px solid;
			border-color: transparent transparent $white transparent;
			z-index: 2;
		}

		&::after {
			content: '';
			position: absolute;
			top: -22px;
			width: 0px;
			height: 0px;
			border: 11px solid;
			border-color: transparent transparent #d1dee580 transparent;
			z-index: 1;
			border-radius: 3px;
		}

		&--personal {
			&::after,
			&::before {
				left: 12%;
			}
		}

		&--professional {
			&::after,
			&::before {
				left: calc(50% - 7.5px);
			}
		}

		&--family {
			&::after,
			&::before {
				left: 82%;
			}
		}

		.details {
			border-bottom: 1px solid #d1dee580;
			@include padding(15px 0);

			&:last-child {
				border-bottom: none;
			}
		}

		.detail-label {
			font-size: $font-size-sm;
			opacity: 0.5;
			text-transform: capitalize;
		}

		.detail-value {
			max-width: 250px;
			text-align: right;
			font-size: $font-size-sm;
			overflow-wrap: break-word;
			@include paddingLeft(10px);
		}
	}

	.profile-edit-btn {
		position: absolute;
		top: 2px;
		right: 2px;
		width: 25px;
		height: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 0.88px solid #d1dee5;
		box-shadow: 0px 1.76px 2.65px 0px #0000001a;
		background: rgba($white, 0.6);
		border-radius: 5px;
		cursor: pointer;

		.edit-icon {
			width: 20px;
			height: 20px;

			path {
				fill: #2196f3;
			}
		}
	}

	.modal-content {
		text-align: center;
		width: calc(100vw - 40px);
		max-width: 410px;

		.upload-title {
			font-size: $font-size-xl;
			font-weight: $font-bold;
			@include marginBottom(20px);
		}

		.profile-pic-wrapper {
			width: 100px;
			height: 100px;
			border-radius: 5px;
			overflow: hidden;

			.profile-pic {
				width: 100%;
				height: 100%;
			}
		}

		.upload-img-wrapper {
			border: 1px dashed rgba(0, 0, 0, 0.5);
			@include padding(20px);
			@include margin(20px);
			border-radius: 5px;
			cursor: pointer;

			.upload-profile-icon {
				width: 50px;
				height: 50px;
				@include marginBottom(5px);
			}

			.upload-photo-text {
				font-size: $font-size-browser-default;
				opacity: 0.7;
				font-weight: $font-light;
			}

			.photo-size-text {
				font-size: $font-size-xs;
				opacity: 0.4;
				@include paddingTop(10px);
			}
		}

		.browse-button-wrapper {
			display: flex;
			align-items: center;
			@include margin(0 20px);

			.secondary-button {
				border: 1px solid $primary;
			}

			.primary-button {
				font-size: $font-size-sm;
			}
		}
	}

	// pending
	.personal-profile {
		height: calc(100vh - 506px);
	}

	.z-index--7 {
		z-index: 7;
	}
}

.suggestion-wrapper {
	.suggestion-title {
		@include padding(20px 20px 10px);
	}

	.suggestion-box {
		width: calc(100% - 40px);
		min-height: 150px;
		resize: vertical;
		border-radius: 5px;
		border: 1px solid #d1dee5;
		@include padding(15px);
		@include margin(0 20px 20px);
		font-size: $font-size-default;
		outline: none;
	}

	.suggestion-button {
		width: calc(100% - 40px);
		@include margin(0 20px);
		@extend .justify-align--center;
	}
}

.settings-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: calc(100% - 40px);
	height: 54px;
	@include padding(15px 19px);
	border-radius: 5px;
	border: 1px solid #d1dee580;
	box-shadow: 0px 3px 5px 0px #0000001a;
	@include margin(20px 20px 20px);

	.toggle-field {
		display: none;
	}

	.toggle-label {
		cursor: pointer;
		text-indent: 0;
		width: 46px;
		height: 15px;
		background: $white;
		display: block;
		border-radius: 100px;
		position: relative;
		border: 1px solid #d1dee5;

		&:after {
			content: '';
			position: absolute;
			top: -5px;
			left: -5px;
			width: 23px;
			height: 23px;
			background: #d1dee5;
			border-radius: 50%;
			transition: 0.3s;
		}

		&:active {
			&:after {
				width: 30px;
			}
		}
	}

	.toggle-field:checked + label {
		background: $white;
	}

	.toggle-field:checked + label:after {
		left: calc(100% + 5px);
		background: #bada55;
		transform: translateX(-100%);
	}
}

.logout-wrapper {
	max-width: 414px;
	width: calc(100% - 40px);
	height: 285px;
	background-color: $white;
	border-radius: 10px;
	@extend .justify-align--center;
	flex-direction: column;

	.logout-sub--title {
		max-width: 300px;
		text-align: center;
		@include marginBottom(30px);
	}

	.logout-button,
	.primary-button {
		max-width: 172px;
		border-radius: 10px;
		font-weight: $font-medium;
	}

	.logout-button {
		border: 1px solid #bfbfbf;
		color: $black;
	}
}

.notification-container {
	position: fixed;
	width: 100%;
	height: 100vh;
	max-width: 450px;
	top: 0;
	background-color: $white;
	z-index: 7;

	.notification-title {
		color: #808080;
		@include marginBottom(20px);
	}

	.notification-box {
		width: 100%;
		height: -webkit-fit-content;
		height: -moz-fit-content;
		height: fit-content;
		@include padding(20px);
		border-radius: 5px;
		background: #ffffff;
		border: 1px solid #e9eff3;
		@include marginBottom(20px);

		.notification {
			@include marginBottom(10px);
		}

		.notification-time {
			font-size: $font-size-xxs;
			opacity: 0.5;
		}
	}

	.notification-box-wrapper {
		@include padding(110px 20px 20px);
		overflow-y: scroll;
		height: 100%;
	}
}

.no-notification-container {
	@extend .justify-align--center;
	flex-direction: column;

	.no-notification-text {
		max-width: 290px;
		opacity: 0.5;
		text-align: center;
		@include marginTop(20px);
	}
}

.edit-profile-wrapper {
	height: calc(100vh - 90px);

	.save-btn {
		position: absolute;
		z-index: 3;
		width: 80px;
		top: -65px;
		right: 20px;
		@include padding(0 10px);
		height: 40px;
		border-radius: 5px;
	}

	.details-input-wrapper {
		width: 100%;
		@include marginRight(0px);

		.contact-input-container {
			.contact-input-wrapper {
				width: 100%;

				.number-input {
					width: calc(100% - 50px);
					@include marginTop(0);
					height: 40px;
				}
			}

			.contact-input {
				width: 40px;
				height: 40px;
				@include padding(10px);
				@extend .justify-align--center;
			}

			.plus-icon {
				border-radius: $border-radius-md;
				border: 1px dashed $border-color;
				background: rgba(240, 243, 246, 0.5);
			}

			.transparent-border {
				border: 1px solid transparent;
			}
		}

		.delete-info-wrapper {
			cursor: pointer;
			width: 50px;
			height: 50px;
			border-radius: 8px;
			display: flex;
			justify-content: center;
			align-items: center;
			@include margin(31px 0 0 20px);
		}

		.delete-icon-wrapper {
			width: 20px;
			height: 20px;
			border-radius: 50%;
			@extend .justify-align--center;
			background: linear-gradient(0deg, rgba(190, 1, 1, 0.5), rgba(190, 1, 1, 0.5)),
				linear-gradient(0deg, #ffffff, #ffffff);
		}

		.delete-contact-number {
			width: 60px;
			@include margin(0);
		}
	}

	.stepper-main-wrapper {
		background: $white;
		width: 100%;
		display: flex;
		justify-content: center;

		&::-webkit-scrollbar {
			width: 0px !important;
			box-shadow: none !important;
		}

		&::-webkit-scrollbar-thumb,
		&::-webkit-scrollbar-track {
			background-color: transparent;
			box-shadow: none !important;
		}

		.scroller {
			width: 100%;
			cursor: pointer;
			overflow-x: auto;
			overflow-y: hidden;

			&::-webkit-scrollbar {
				width: 0px !important;
				height: 0px !important;
				box-shadow: none !important;
			}

			&::-webkit-scrollbar-thumb,
			&::-webkit-scrollbar-track {
				background-color: transparent;
				box-shadow: none !important;
			}
		}

		.stepper-inner-wrapper {
			width: -webkit-fit-content;
			width: -moz-fit-content;
			width: fit-content;
			display: flex;
			justify-content: flex-start;
			position: relative;

			&::after {
				content: '';
				position: absolute;
				width: 545px;
				background-color: #d1dee5;
				height: 1px;
				top: 43px;
				left: 20px;
			}

			&::-webkit-scrollbar {
				display: none;
				width: 0px !important;
				box-shadow: none !important;
			}

			&::-webkit-scrollbar-thumb,
			&::-webkit-scrollbar-track {
				background-color: transparent;
				box-shadow: none !important;
			}
		}

		.horizontal-stepper-wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			@include margin(20px 10px 10px);

			&:first-child {
				@include marginLeft(0);
			}

			.horizontal-stepper-box {
				// width: 104px;
				@include padding(8px 16px);
				height: 46px;
				border-radius: 10px;
				@extend .justify-align--center;
				z-index: 2;
				transition: all 0.3s ease-in-out;
				cursor: pointer;
				border: 2px solid $white;
				position: relative;
				border: 1px solid #d1dee5;
				@include padding(6px 18px);
				background-color: $white;
				text-transform: capitalize;
			}

			.horizontal-stepper-fill-box {
				background: linear-gradient(0deg, #0174be, #0174be), linear-gradient(0deg, $white, $white);
				box-shadow: 0px 4px 6px 0px #0c356a33;
				background-color: $primary;
			}

			.stepper-title {
				line-height: 15px;
				transition: all 0.3s ease-in-out;

				&--active {
					color: $white;
				}
			}

			.stepper-hr {
				width: 20px;
				height: 2px;
				@include marginTop(5px);
				background-color: $white;
				border-radius: 10px;
				transition: all 0.3s ease-in-out;

				&--active {
					background-color: $primary;
				}
			}
		}
	}
}

.edit-profile-container {
	overflow-y: auto;
	max-height: calc(100vh - 172px);
}

.profile-container {
	@include padding(10px 20px 0);

	.tab-option-container {
		gap: 20px;
		@include paddingTop(15px);

		.small-tab {
			width: 74px;
		}

		.big-tab {
			width: 100px;
		}

		.tab-option {
			@extend .justify-align--center;
			height: 50px;
			padding: 10px;
			border-radius: $border-radius-md;
			background: $catskillWhite;

			.tab-title {
				font-size: $font-size-browser-default;
				font-weight: $font-regular;
				opacity: 0.5;
				color: $secondary;
			}

			&--selected {
				background: #ffc436;

				.tab-title {
					font-weight: $font-medium;
					color: $black;
					opacity: 1;
				}
			}
		}
	}

	// map style
	.google-map-wrapper {
		width: 100%;
		height: 300px;

		.autocomplete-container {
			input {
				margin-top: 0;
				width: calc(100vw - 40px);
				max-width: 410px;
				height: 50px;
				color: black;
			}
		}
	}

	// .google-map-wrapper {
	// 	width: 100%;
	// 	height: 110px;
	// 	position: relative;
	// 	border-radius: 8px;
	// 	border: 1px solid $border-color;
	// 	margin-top: 120px;

	// 	.map-container {
	// 		.desktop-note {
	// 			position: absolute;
	// 			top: -48px;
	// 		}

	// 		.map-input {
	// 			position: absolute;
	// 			top: -121px;
	// 		}

	// 		.mobile-note {
	// 			position: absolute;
	// 			top: -48px;
	// 		}

	// 		.dropdown-wrapper {
	// 			width: 100%;
	// 			position: absolute;
	// 			margin-top: 15;
	// 			box-shadow: 0px 4px 6px 0px rgba(12, 53, 106, 0.2);
	// 			top: -70px;
	// 			left: 0;
	// 			z-index: 9999;
	// 			border-radius: $border-radius-md;
	// 			background: $catskillWhite;

	// 			.dropdown-option {
	// 				background: $white;
	// 				padding: 15px;
	// 				color: $black;
	// 				opacity: 0.5;
	// 				font-size: 16px;
	// 				font-weight: 400;
	// 				border-bottom: 1px solid $border-color;
	// 			}
	// 		}
	// 	}
	// }

	.xxGHyP-dialog-view,
	.uNGBb-dialog-view--content {
		display: none !important;
	}

	.delete-icon-wrapper {
		width: 20px;
		height: 20px;
		border-radius: 50%;
		@extend .justify-align--center;
		background: linear-gradient(0deg, rgba(190, 1, 1, 0.5), rgba(190, 1, 1, 0.5)),
			linear-gradient(0deg, #ffffff, #ffffff);
	}

	.add-education-button {
		width: 380px;
		border: 1px dashed $border-color;
		background: rgba(240, 243, 246, 0.5);
		box-shadow: none;
		color: rgba($black, 0.5);
		@extend .flex--row-reverse;
		align-items: center;
		display: flex;
		justify-content: center;
	}

	.checkbox-wrapper {
		color: rgba(#0c356a, 0.5);
		font-size: $font-size-sm;
		@include padding(15px 10px 10px 32px);

		.check-mark {
			left: 0;
			top: 15px;
			border-radius: 5px;
			border-color: #d1dee5;
		}

		input {
			&:checked ~ .check-mark {
				border-color: $primary;
				background-color: $primary;
			}
		}
	}
}

.profile-pic-full-wrapper {
	position: fixed;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	backdrop-filter: blur(5px);
	background-color: rgba(0, 0, 0, 0.3);
	top: 0;
	left: 0;
	z-index: 9;
	border-radius: 0;
	border: none;
	@include margin(0);
	@extend .justify-align--center;

	.profile-image {
		border-radius: 0;
		-o-object-fit: contain;
		object-fit: contain;
		width: 100%;
		height: 100%;
	}

	.close--icon {
		position: absolute;
		right: 10px;
		top: 10px;
		padding: 3px;
		background-color: $white;
		border-radius: 4px;
		cursor: pointer;
		pointer-events: none;

		g {
			opacity: 1;
		}
	}
}
