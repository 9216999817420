.header-wrapper {
	width: $full-width;
	max-width: 450px;
	height: 90px;
	position: fixed;
	top: 0;
	z-index: 3;
	background-color: $white;
	box-shadow: 0px 3px 5px 0px rgba($black, 0.102);

	.navbar {
		box-sizing: border-box;
		@include padding(20px);
		background: linear-gradient(
			180deg,
			rgba($white, 0.2) -72.92%,
			rgba(242, 244, 248, 0.196875) -72.9%,
			rgba(255, 201, 60, 0.2) 100%
		);

		.logo {
			width: 40px;
			height: 40px;
			position: relative;

			&::after {
				content: '';
				width: 1px;
				height: 60px;
				position: absolute;
				background-color: rgba($color: $white, $alpha: 0.3);
				top: -10px;
				right: -20px;
			}
		}

		.header-links {
			gap: 20px;
		}

		.link {
			line-height: 35px;
			border-bottom: 1px solid transparent;
			transition: all 0.5s ease;

			&:hover,
			&.active {
				border-color: $white;
			}
		}

		.user-tab {
			gap: 20px;

			.name {
				width: 120px;
			}

			.logout-icon {
				fill: $white;
			}
		}

		.header-icon-wrapper {
			width: 40px;
			height: 40px;
			border-radius: 5px;
			box-shadow: 0px 2px 3px 0px rgba($black, 0.102);
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			background: $white;
		}
	}
}

//notification
.notification-wrapper {
	max-width: 1300px;
	position: absolute;
	top: 60px;
	z-index: 2;
	right: 200px;
	box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
	padding: 15px;

	.notification-btn {
		height: 40px;
		line-height: 22px;
		min-width: 100px;
		outline: none;
		transition: all 0.5s ease;
		padding: 0 20px;
	}

	.notification-btn-active {
		border-bottom: 1px solid $black;
	}

	.clear-filter-button {
		@include button(0px, 8px);
		color: $white;
		font-size: 13px;
	}
}

//responsive
@media screen and (max-width: 1600px) {
	// Header
	.header-wrapper {
		.navbar {
			.link {
				line-height: 23px;
			}
		}
	}
}
