.footer-container {
	box-shadow: 0px -4px 14px 0px #00000026;
	height: 90px;
	border-radius: 20px 20px 0 0;

	.footer-tab-wrapper {
		width: 33.33%;
		height: 100%;
		flex-direction: column;
		@extend .justify-align--center;
		cursor: pointer;

		.footer-tab-title {
			color: rgba($black, 0.5);
			font-size: $font-size-browser-default;
			font-weight: $font-regular;
			text-transform: capitalize;
			transition: all 0.3s ease-in-out;

			&--active {
				color: $primary;
				font-weight: $font-bold;
			}
		}

		.footer-active-icon {
			g {
				path {
					transition: stroke 0.3s ease-in-out;
					stroke: $primary;
				}
			}
		}
	}
}

.footer-container {
	background-color: #fafcff;
}

.scaletech-footer-container,
.footer-container {
	width: 100%;
	max-width: 450px;
	position: fixed;
	bottom: 0;
	z-index: 6;
	@extend .justify-align--center;
	justify-content: space-between;
}

.scaletech-footer-container {
	justify-content: center;
	@include paddingBottom(30px);

	.footer-title {
		opacity: 0.5;
	}
}

.home-scaletech-footer-container,
.login-scaletech-footer-wrapper,
.alumni-details-footer-wrapper {
	position: unset;
}

.login-scaletech-footer-wrapper {
	@include paddingBottom(20px);
}

.splash-footer-container {
	background-color: transparent;
}
