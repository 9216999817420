.primary-button {
	@include button(false, 0px 15px, 240px);

	&:hover {
		background: linear-gradient(0deg, rgba($black, 0.05) 0%, rgba($black, 0.05) 100%), $primary;
	}

	&:disabled {
		opacity: 0.4;
	}

	&:active {
		border: 2px solid $secondary;
	}

	&.icon {
		border: 0;
		box-shadow: none;
		@include padding(0);
		width: 30px;
		height: 30px;
		background: transparent;
	}
}

.secondary-button {
	@include button(true, 0px 15px, 240px);
	font-size: $font-size-sm;

	&:hover {
		background: linear-gradient(0deg, rgba(52, 65, 84, 0.07) 0%, rgba(52, 65, 84, 0.07) 100%), $white;
	}

	&:disabled {
		opacity: 0.5;
		box-shadow: unset;
	}

	&:active {
		border: 2px solid rgba(116, 100, 210, 0.8);
	}
}

.transparent-button {
	@include button(true, 0px 15px, 240px);
	font-size: $font-size-browser-default;
	color: $white;
	background: rgba(215, 217, 236, 0.3);
	box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.35);

	&:hover {
		box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5);
	}

	&:disabled {
		opacity: 0.5;
		box-shadow: unset;
	}
}
