.constitution-wrapper {
	@include margin(20px);

	.constitution-check-box {
		@include margin(20px 0);
		@include padding(5px);
		border: 1px solid #d1dee5;
		border-radius: 5px;
		border-block-end: 4px solid #d1dee5;

		.check-mark {
			top: 11px;
			left: 9px;
			width: 20px;
			height: 20px;

			&::after {
				left: 6px;
				top: 1px;
			}
		}

		.link {
			margin-left: 10px;
			color: $primary;
		}
	}

	.constitution-button {
		padding: 14px 40px;
		border-radius: 5px;
		font-size: 16px;
		font-weight: bold;

		&.accept {
			color: $green;
			background-color: #01be091a;
			border: 1px solid $green;
		}

		&.reject {
			color: getColor($red, 700);
			background-color: #c200001a;
			border: 1px solid getColor($red, 700);
		}

		&.close {
			padding: 13px 55px;
			font-weight: normal;
			background-color: transparent;
			border: 1px solid getColor($grey, 50);
		}

		&.submit {
			color: $white;
			padding: 13px 55px;
			background-color: $primary;
			border: 1px solid getColor($grey, 50);
		}
	}

	.rejection-reason-text-area {
		width: 100%;
		min-height: 125px;
		resize: vertical;
		border-radius: 5px;
		border: 1px solid #d1dee5;
		@include padding(15px);
		@include marginTop(10px);
		font-size: $font-size-sm;
		outline: none;
	}

	.text--red {
		color: getColor($red, 200);
	}
}
