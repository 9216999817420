.swiper-card {
	background: $white;
	width: 165px;
	min-height: 200px;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;

	.profile-img {
		height: 100%;
		width: 100%;
		max-height: 150px;
		border-radius: 5px;
		border: 5px solid #ffffff;
		@include marginBottom(7px);
		object-fit: cover;
		-o-object-fit: cover;
		object-position: top;
		-o-object-position: top;
	}

	.friend-name {
		@include marginBottom(5px);
		text-align: center;
		font-weight: $font-bold;
		line-height: 15px;
	}

	.friend-designation {
		@include marginBottom(5px);
		font-size: $font-size-xxs;
		line-height: 15px;
		text-align: center;
		opacity: 0.5;
	}

	.contact-wrapper {
		width: 34px;
		height: 34px;
		border-radius: 5px;
		box-shadow: 0px 2px 3px 0px rgba($black, 0.1);
		@extend .justify-align--center;

		.email-icon,
		.mobile-icon {
			width: 23px;
			height: 30px;
		}

		.email-icon {
			width: 20px;
		}

		.wp-icon {
			width: 30px;
			height: 30px;
		}
	}
}
