.all-memory-wrapper {
	.memory-details-wrapper {
		@include marginTop(20px);
		overflow-y: auto;
		height: calc(100vh - 190px);

		.memory-img {
			max-width: 123px;
			height: 123px;
			-o-object-fit: cover;
			object-fit: cover;
			object-position: top;
			-o-object-position: top;
			border-radius: 5px;
			width: calc(33.33% - 10px);
			@include margin(10px 5px);
			overflow: hidden;
			cursor: pointer;
			position: relative;

			&--last {
				&::after {
					content: attr(data-content);
					width: 100%;
					height: 100%;
					position: absolute;
					background-color: rgba($color: $black, $alpha: 0.3);
					top: 0;
					right: 0;
					@extend .justify-align--center;
					color: $white;
				}
			}
		}

		.detail-memory-img {
			cursor: pointer;
			background: #d9d9d9;
			width: calc(33.33% - 10px);
		}
	}

	.memory-swiper {
		position: fixed;
		width: 100%;
		height: 100vh;
		max-width: 450px;
		top: 0;
		background-color: $white;
		z-index: 3;
	}
}
