@mixin margin($value...) {
	margin: $value;
}

@mixin marginTop($value) {
	margin-top: $value;
}

@mixin marginBottom($value) {
	margin-bottom: $value;
}

@mixin marginLeft($value) {
	margin-left: $value;
}

@mixin marginRight($value) {
	margin-right: $value;
}

@mixin padding($value...) {
	padding: $value;
}

@mixin paddingTop($value) {
	padding-top: $value;
}

@mixin paddingBottom($value) {
	padding-bottom: $value;
}

@mixin paddingLeft($value) {
	padding-left: $value;
}

@mixin paddingRight($value) {
	padding-right: $value;
}

@mixin transition($property, $duration, $timingFun: '') {
	@if $timingFun== '' {
		-webkit-transition: $property $duration;
		transition: $property $duration;
	} @else {
		-webkit-transition: $property $duration $timingFun;
		transition: $property $duration $timingFun;
	}
}

@mixin getShadesColorClasses($colors, $property, $isImportant: '') {
	@each $key, $value in $colors {
		&-#{$key} {
			@if $isImportant == 'important' {
				#{$property}: $value !important;
			} @else {
				#{$property}: $value;
			}
		}
	}
}

@mixin button($transparent, $padding, $width: 100%, $height: 40px) {
	padding: $padding;
	height: $height;
	width: $width;
	font-weight: $font-bold;
	font-size: $font-size-browser-default;
	border: 1px solid transparent;
	line-height: $line-height-sm;
	border-radius: 5px;
	box-shadow: $primary-box-shadow;
	transition: all 0.5s ease;
	@extend .justify-align--center;

	@if ($transparent) {
		background-color: $white;
		color: $secondary;
	} @else {
		background: $primary;
		color: $white;
	}
}
