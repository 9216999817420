.form-input {
	cursor: text;
	width: $full-width;
	height: 40px;
	@include padding(0 15px);
	@include marginTop(10px);
	border: none;
	border-radius: $border-radius-default;
	background-color: $white;
	color: $gray;
	outline: 0;
	font-size: $font-size-default;
	border: 1px solid $border-color;

	&:disabled {
		cursor: not-allowed;
		background-color: #e7e7e7;
	}

	&::placeholder {
		color: $secondary;
		opacity: 0.5;
	}

	&--error {
		border: 1px solid #f44336;
	}
}

.checkbox {
	width: 32px;
	min-width: 32px;
	height: 32px;
	border: 1px solid rgba(255, 255, 255, 0.3);
	transition: all 0.2s ease-in-out;

	&:hover {
		border: 1px solid $white;
	}

	.check-icon {
		width: 26px;
		height: 26px;
		path {
			stroke: $primary;
		}
	}

	&.player-select {
		border-radius: $border-radius-circle;
		.check-icon {
			width: 22px;
			height: 22px;
			path {
				stroke: $white;
			}
		}
		&.selected {
			background-color: $green;
		}
	}
}

.text-area {
	height: auto;
	padding: 15px;
}

.form-item {
	margin-bottom: 20px;
}

.form-label {
	margin-bottom: 5px;
	display: block;
	&.required {
		position: relative;
		width: fit-content;

		&::after {
			content: '*';
			color: red;
			font-size: 1rem;
			position: absolute;
			right: -10px;
			top: 0;
		}
	}
}

.calendar-input {
	width: 100%;
	padding: 0 15px !important;
}

.react-datepicker__input-container {
	.calendar-icon {
		width: 20px !important;
		height: 20px !important;
		border-radius: 5px;
		background: $white;
		@extend .justify-align--center;
		top: 12px;
		right: 2px;
		padding: 8px !important;
		pointer-events: none;
	}

	.membership-calendar-icon {
		right: 2px;
	}
}
