.modal-wrapper {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	background-color: rgba($color: $black, $alpha: 0.65);
	z-index: 6;
	@extend %flex;
	-webkit-animation: fadeIn 0.3s;
	animation: fadeIn 0.3s;
	@extend .justify-align--center;

	.modal-content {
		width: $full-width;
		max-width: 450px;
		background-color: $white;
		@include margin(auto);
		border-radius: 5px;
		position: relative;
		@extend %box-size--border-box;

		.close-button {
			top: 15px;
			right: 5px;
		}

		.heading-wrapper {
			padding: 15px 20px;
			border-bottom: 1px solid $border-color;
		}

		.body-wrapper {
			padding: 20px;
			max-height: 80vh;
			overflow-y: scroll;
		}

		.footer-wrapper {
			padding: 20px;

			.cancel-btn {
				background-color: #dddddd;
				color: #666666;
			}
		}
	}

	//confirm Modal
	.confirm-modal {
		max-width: 500px;

		.confirmation-message {
			padding: 46px 35px;
		}

		.cancel {
			background-color: #dddddd;
			color: #666666;
			border-radius: 0 0 0 5px;
		}

		.success {
			color: $white;
			border-radius: 0 0 3px 0;
		}
	}

	//add comment Modal
	.add-comment-modal {
		.body-wrapper {
			padding: 0;
		}
	}
}

@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.install-prompt-wrapper {
	position: fixed;
	width: 100vw;
	top: 2px;
	left: 0;
	z-index: 998;
	border-radius: 4px;
	box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);

	.install-prompt {
		@include padding(12px 10px);
		position: relative;
		background-color: $white;
		border-radius: 4px;
		display: flex;
		justify-content: space-between;

		.close-icon-wrapper {
			position: absolute;
			top: 0px;
			right: 3px;

			.close-icon {
				width: 15px;
				g {
					opacity: 1;
				}
			}
		}

		.prompt-logo {
			width: 40px;
			@include marginRight(15px);
		}
	}
}

.notification-prompt-wrapper {
	position: fixed;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.3);
	justify-content: center;
	top: 0;
	left: 0;
	z-index: 999;
	border-radius: 4px;
	box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);

	.install-prompt {
		@include padding(20px 10px);
		position: relative;
		background-color: $white;
		border-radius: 4px;
		max-width: 300px;
		text-align: center;
		.close-icon-wrapper {
			position: absolute;
			top: 0px;
			right: 3px;

			.close-icon {
				width: 15px;
				g {
					opacity: 1;
				}
			}
		}

		.prompt-logo {
			@include marginBottom(10px);
			width: 40px;
		}
	}
}

.constitution-modal-wrapper {
	.constitution-modal-content {
		width: 90%;
		@include padding(20px);

		.close-button {
			top: 7px;
			right: 7px;
		}

		.constitution-logo {
			width: 40px;
		}
	}

	.comment-modal-content {
		position: absolute;
		bottom: 0;
		width: 100%;
		border-radius: 40px 40px 0px 0px;
		@include padding(20px);

		.close-button {
			top: 7px;
			right: 7px;
		}

		.constitution-logo {
			width: 40px;
		}
	}
}
